import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AlertMessage from "./components/AlertMessage/AlertMessage";
import DeleteDialog from "./components/deletedialog";
import Loader from "./components/loader";
import Navbar from "./components/navbar";
import ForgotPassword from "./pages/forgot-password";
import LoginPage from "./pages/login";
import Signup from "./pages/signup";
import VerifyAccount from "./pages/verify-account";
import { useAppSelector } from "./store/hooks";
import AuthProvider from "./utils/AuthContext";

const DashboardPage = lazy(() => import("./pages/dashboard"));
const LeadsPage = lazy(() => import("./pages/leads"));
const ClientsPage = lazy(() => import("./pages/clients"));
const ProjectsPage = lazy(() => import("./pages/projects"));
const ProjectQuotationsPage = lazy(
  () => import("./pages/projects/ProjectQuotations")
);
const ProjectDetailsPage = lazy(
  () => import("./pages/projects/projectDetailsPage")
);
const Quotation = lazy(() => import("./pages/quotation"));
const CreateQuotationPage = lazy(
  () => import("./pages/quotation/create-quotation-page")
);
const QuotationSettings = lazy(() => import("./pages/quotation-settings"));
const CataloguePage = lazy(() => import("./pages/catalogue"));
const Profile = lazy(() => import("./pages/profile"));

function App() {
  const deleteDialog = useAppSelector((state) => state.ui.deleteDialog);

  return (
    <div className="font-primary">
      <Loader />
      <DeleteDialog open={deleteDialog.show} onDelete={deleteDialog.onDelete} />
      <AlertMessage />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<DashboardPage />} />
            <Route path="leads" element={<LeadsPage />} />
            <Route path="clients" element={<ClientsPage />} />
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="projects/:id" element={<ProjectDetailsPage />} />
            <Route
              path="projects/:id/quotations"
              element={<ProjectQuotationsPage />}
            />
            <Route path="quotation" element={<Quotation />} />
            <Route path="quotation/create" element={<CreateQuotationPage />} />
            <Route path="quotation/update" element={<CreateQuotationPage />} />
            <Route path="quotation-settings" element={<QuotationSettings />} />
            <Route path="catalogue" element={<CataloguePage />} />
            <Route path="profile" element={<Profile />} />
            <Route path="verify-account" element={<VerifyAccount />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
