import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { db, functions } from "../firebase";
import {
  Company,
  Profile,
  setCompany,
  setProfile,
  setUsers,
} from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import { useAuth } from "../utils/AuthContext";
import { logFirestoreEvent } from "../utils/logger";
import useError from "./useError";
import useFileUpload from "./useFileUpload";

export interface AddUserForm {
  email: string;
  role: string;
}

const useProfile = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();
  const { uploadFile } = useFileUpload();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { user, profile, company, users } = useAppSelector(
    (state) => state.data
  );

  const getCompanyInfo = useCallback(
    async (forceRefresh = false) => {
      if (company && !forceRefresh) return;
      try {
        dispatch(setShowBackdrop(true));
        const path = `/tenants/${user?.tenantId}`;
        logFirestoreEvent("getCompanyInfo", { path });
        const snap = await getDoc(doc(db, path));
        const data = snap.data() as Company;
        dispatch(setCompany(data));
        if (data.planDetails?.planId === null) {
          navigate("/subscription");
        }
      } catch (error) {
        handleError(error, "getCompanyInfo");
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company, dispatch, user?.tenantId]
  );

  const getProfile = useCallback(async () => {
    if (profile) return;
    if (!currentUser) return;
    try {
      dispatch(setShowBackdrop(true));
      const path = `/users/${user?.uid}`;
      logFirestoreEvent("getProfile", { path });
      const snap = await getDoc(doc(db, path));

      const idTokenResult = await currentUser?.getIdTokenResult(false);
      const tenants = idTokenResult?.claims.tenants as Record<
        string,
        { role: string }
      >;
      const role = user?.tenantId ? tenants?.[user.tenantId]?.role : undefined;

      const data = { ...snap.data(), uid: snap.id, role } as Profile;
      dispatch(setProfile(data));
    } catch (error) {
      handleError(error, "getProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, dispatch, profile, user?.tenantId, user?.uid]);

  const getUsers = useCallback(
    async (update = false) => {
      if (!update && users.length) return;

      dispatch(setShowBackdrop(true));

      try {
        const tenantId = user?.tenantId;
        if (!tenantId) throw new Error("Tenant ID is undefined");

        const userPath = `/tenants/${tenantId}/users`;
        logFirestoreEvent("getUsers", { userPath });

        // Fetch tenant user IDs and pending invitations in parallel
        const [tenantSnap, invitationsSnap] = await Promise.all([
          getDocs(collection(db, userPath)),
          getDocs(
            query(
              collection(db, "/invitations"),
              where("tenantId", "==", tenantId),
              where("status", "==", "pending")
            )
          ),
        ]);

        // Extract user IDs from tenant collection
        const userIds = tenantSnap.docs.map((doc) => ({
          id: doc.id,
          role: doc.data()["role"],
        }));

        // Fetch user profiles concurrently
        const userDocsSnapshots = await Promise.all(
          userIds.map(({ id }) => getDoc(doc(db, "users", id)))
        );

        // Map valid user profiles
        const allUsers: Profile[] = userDocsSnapshots
          .filter((docSnap) => docSnap.exists())
          .map(
            (docSnap) =>
              ({
                ...docSnap.data(),
                uid: docSnap.id,
                isAccepted: true,
                role: userIds.find((e) => e.id === docSnap.id)?.role,
              } as Profile)
          );

        // Add pending invitations
        const invitationData: Profile[] = invitationsSnap.docs.map((doc) => ({
          ...doc.data(),
          uid: doc.id,
          isAccepted: false,
        })) as Profile[];

        // Update state with combined user list
        dispatch(setUsers([...allUsers, ...invitationData]));
      } catch (error) {
        handleError(error, "getUsers");
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, user?.tenantId, users.length]
  );

  const updateProfile = async (data: Partial<Profile>) => {
    try {
      dispatch(setShowBackdrop(true));
      const path = `/users/${user?.uid}`;
      logFirestoreEvent("updateProfile", { path });
      await updateDoc(doc(db, path), {
        ...data,
        updatedAt: serverTimestamp(),
      });
      if (profile) dispatch(setProfile({ ...profile, ...data }));
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Profile updated successfully!",
          type: "success",
        })
      );
    } catch (error) {
      handleError(error, "updateProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateLogo = async (file: File): Promise<boolean> => {
    try {
      dispatch(setShowBackdrop(true));
      const path = `/tenants/${user?.tenantId}`;
      logFirestoreEvent("updateLogo", { path });
      const fileData = await uploadFile(file, `${path}/logo`);
      await updateDoc(doc(db, path), {
        logo: fileData,
        updatedAt: serverTimestamp(),
      });
      if (company) dispatch(setCompany({ ...company, logo: fileData }));
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Logo updated successfully!",
          type: "success",
        })
      );
      return true;
    } catch (error) {
      handleError(error, "updateLogo");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const inviteTeamMember = async ({ email, role }: AddUserForm) => {
    try {
      dispatch(setShowBackdrop(true));
      logFirestoreEvent("inviteTeamMember");

      // Check if user exists
      const isExists = users.find((e) => e.email === email);

      if (isExists) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Email already exists!",
            type: "error",
          })
        );
        return false;
      }

      // Call Firebase function to add user
      const sendTeamInvitation = httpsCallable(functions, "sendTeamInvitation");
      await sendTeamInvitation({
        email,
        tenantId: user?.tenantId,
        role,
        company: company?.companyName,
      });

      await getUsers(true);
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "You have invited the new member successfully!",
          type: "success",
        })
      );

      return true;
    } catch (error) {
      handleError(error, "inviteTeamMember");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const removeMember = async (uid: string, fromInvitation: boolean) => {
    try {
      dispatch(setShowBackdrop(true));
      const removeUser = httpsCallable(functions, "removeUser");
      const response = await removeUser({
        uid,
        tenantId: user?.tenantId,
        fromInvitation,
      });
      dispatch(
        setShowSnackbar({
          open: true,
          msg: (response.data as { message: string })["message"],
          type: "success",
        })
      );

      const updatedUsers = users.filter((e) => e.uid !== uid);
      dispatch(setUsers(updatedUsers));
    } catch (error) {
      handleError(error, "removeMember");
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const sendEmailVerification = async (name: string, email: string) => {
    logFirestoreEvent("sendEmailVerification");
    dispatch(setShowBackdrop(true));
    const callFunction = httpsCallable(functions, "sendVerificationEmail");
    await callFunction({ name, email, tenantId: user?.tenantId })
      .then(() => {
        // return true;
      })
      .catch((error) => {
        handleError(error, "sendVerificationEmail");
      })
      .finally(() => {
        dispatch(setShowBackdrop(false));
      });
  };

  const getTenants = useCallback(
    async (tenantIds: string[]) => {
      if (!tenantIds.length) return [];

      try {
        dispatch(setShowBackdrop(true));
        logFirestoreEvent("getTenants", { path: tenantIds });

        const tenantPromises = tenantIds.map(async (tenantId) => {
          const snap = await getDoc(doc(db, `/tenants/${tenantId}`));
          const data = snap.data();
          return { id: snap.id, name: data?.companyName };
        });

        return await Promise.all(tenantPromises);
      } catch (error) {
        handleError(error, "getCompanyInfo");
        return [];
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return {
    getCompanyInfo,
    getProfile,
    updateProfile,
    updateLogo,
    getUsers,
    inviteTeamMember,
    removeMember,
    sendEmailVerification,
    getTenants,
  };
};
export default useProfile;
