import { Person } from "@mui/icons-material";
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import useProfile from "../../hooks/useProfile";
import { useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  onSelected: (tenantId: string) => void;
  tenantIds: string[];
}
const TenantsDialog = ({ open, onSelected, tenantIds }: Props) => {
  const { getTenants } = useProfile();
  const [data, setData] = useState<{ id: string; name: any }[]>([]);

  useEffect(() => {
    getTenants(tenantIds).then((val) => setData(val));
  }, [getTenants, tenantIds]);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>Select the Company</DialogTitle>
      <List sx={{ pt: 0 }}>
        {data.map((d) => (
          <ListItem disablePadding key={d.id}>
            <ListItemButton onClick={() => onSelected(d.id)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={d.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default TenantsDialog;
