import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { applyActionCode } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { auth } from "../../firebase";
import { useAppDispatch } from "../../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../../store/ui.store";
import { useAuth } from "../../utils/AuthContext";

const VerifyAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { currentUser, sendVerificationEmail } = useAuth();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);

  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setResendDisabled(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendDisabled]);

  const handleVerify = async () => {
    setLoading(true);
    await currentUser?.reload();
    if (!currentUser?.emailVerified) {
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Email not verified!",
          type: "error",
        })
      );
    }
    setLoading(false);
  };

  const verifyEmail = useCallback(
    async (oobCode: string) => {
      try {
        dispatch(setShowBackdrop(true));
        await applyActionCode(auth, oobCode);
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Email verified successfully!",
            type: "success",
          })
        );
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Email verification failed!",
            type: "error",
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (oobCode) {
      verifyEmail(oobCode);
    }
  }, [oobCode, verifyEmail]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        textAlign: "center",
      }}
    >
      <img
        src="/images/verify.svg"
        alt="verify-account"
        className="h-96 pb-10"
      />
      <Typography variant="h5" mb={2}>
        Verify Your Account
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={3}>
        A verification link has been sent to your email. Please check your inbox
        and click the link to verify your account.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2, width: "250px", height: "45px" }}
        onClick={handleVerify}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "I Have Verified"
        )}
      </Button>
      <Button
        sx={{ mt: 2, textTransform: "none" }}
        disabled={resendDisabled}
        onClick={async () => {
          const res = await sendVerificationEmail();
          if (res) setResendDisabled(true);
        }}
      >
        {resendDisabled ? `Resend in ${timer}s` : "Resend Verification Link"}
      </Button>
    </Box>
  );
};

export default VerifyAccount;
