import { collection, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../firebase";
import { useAppDispatch } from "../store/hooks";
import { setShowBackdrop } from "../store/ui.store";
import useError from "./useError";
import { logFirestoreEvent } from "../utils/logger";

export interface SignupForm {
  companyName: string;
  website?: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  cnfPassword: string;
}

const useSignup = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const checkUniqueFields = async (email: string) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    logFirestoreEvent("checkUniqueFields", { path: "email" });
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  };

  const createTenant = async (data: SignupForm): Promise<boolean> => {
    dispatch(setShowBackdrop(true));
    const createTenant = httpsCallable(functions, "createTenant");
    return await createTenant(data)
      .then(() => true)
      .catch((error) => {
        handleError(error, "createTenant");
        return false;
      })
      .finally(() => {
        dispatch(setShowBackdrop(false));
      });
  };

  return { checkUniqueFields, createTenant };
};

export default useSignup;
