import {
  AccountBox,
  Dashboard,
  Inventory,
  Logout,
  Person,
  Portrait,
  RequestQuote,
  Settings,
  Work,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import React, { Suspense, useEffect, useState } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import useProfile from "../../hooks/useProfile";
import { useAppSelector } from "../../store/hooks";
import { useAuth } from "../../utils/AuthContext";
import Loader from "../loader";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NAV_ITEMS = [
  {
    title: "Dashboard",
    link: "/",
    icon: <Dashboard />,
  },
  {
    title: "Leads",
    link: "/leads",
    icon: <Person />,
  },
  {
    title: "Clients",
    link: "/clients",
    icon: <Portrait />,
  },
  {
    title: "Projects",
    link: "/projects",
    icon: <Work />,
  },
  {
    title: "Quotation",
    link: "/quotation",
    icon: <RequestQuote />,
  },
  {
    title: "Quotation Settings",
    link: "/quotation-settings",
    icon: <Settings />,
  },
  {
    title: "Catalogue",
    link: "/catalogue",
    icon: <Inventory />,
  },
  {
    title: "Profile",
    link: "/profile",
    icon: <AccountBox />,
  },
] as const;

const NavBar = () => {
  const navigate = useNavigate();
  const { currentUser, isLoading, signOut } = useAuth();
  const { getCompanyInfo, getProfile, getUsers } = useProfile();

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState<(typeof NAV_ITEMS)[number]["link"]>(
    NAV_ITEMS[0].link
  );

  const { company, profile, users } = useAppSelector((state) => state.data);

  const hasPlan = !!company?.planDetails?.planId;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const item = NAV_ITEMS.find((e) => {
      const regex = new RegExp(`/${e.link.replace("/", "")}/`);
      return regex.test(`${pathname}/`);
    });
    if (item) {
      setSelected(item.link);
    }
  }, []);

  useEffect(() => {
    if (!company && currentUser) {
      getCompanyInfo();
      getProfile();
    }
  }, [company, currentUser, getCompanyInfo, getProfile]);

  useEffect(() => {
    if (profile) getUsers();
  }, [getUsers, profile]);

  useEffect(() => {
    if (currentUser && currentUser.emailVerified && company && !hasPlan) {
      setSelected("/profile");
      navigate("/profile");
    }
  }, [company, currentUser, hasPlan, navigate]);

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return users.length === 0 ? null : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={!currentUser?.emailVerified ? false : open}
        className="print:hidden"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {company?.companyName}
          </Typography>
          <>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  {...stringAvatar(
                    `${profile?.firstName} ${profile?.lastName}`
                  )}
                />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Link to="/profile">
                <MenuItem>
                  <Avatar /> Profile
                </MenuItem>
              </Link>
              <MenuItem onClick={() => signOut()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        </Toolbar>
      </AppBar>
      {currentUser?.emailVerified && (
        <Drawer variant="permanent" open={open} className="print:hidden">
          <DrawerHeader>
            <img src="/logo_text.svg" alt="logo" width="180px" />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {NAV_ITEMS.map((item) => (
              <Link
                key={item.title}
                to={item.link}
                onClick={(e) => !hasPlan && e.preventDefault()}
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    selected={selected === item.link}
                    disabled={!hasPlan}
                    onClick={
                      !hasPlan ? undefined : () => setSelected(item.link)
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: selected === item.link ? "#004080" : null,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 500,
                            color:
                              selected === item.link
                                ? "#004080"
                                : "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {item.title}
                        </Typography>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader className="print:hidden" />
        {isLoading ? (
          <Loader show />
        ) : currentUser ? (
          <Suspense fallback={<Loader show />}>
            <Outlet />
          </Suspense>
        ) : (
          <Navigate to="/login" replace />
        )}
      </Box>
    </Box>
  );
};

export default NavBar;
