import { Box, Button, InputAdornment, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import InputField from "../../components/inputField";
import useSignup, { SignupForm } from "../../hooks/useSignup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setShowSnackbar } from "../../store/ui.store";
import { useAuth } from "../../utils/AuthContext";

const LEFT_CONTENT = {
  "1": {
    title: "Company Name",
    desc: "Your company name helps us identify your business and tailor our services to meet your specific needs. It will be displayed on your dashboard and used in communication.",
    image: "/images/company.svg",
  },
  "2": {
    title: "Email Address",
    desc: "Your email is used for logging into your account and for communication. We will send important updates and information to this email address.",
    image: "/images/email.svg",
  },
  "3": {
    title: "Phone Number",
    desc: "Your phone number is used for communication and adds an extra layer of security. We may use it for account recovery and important notifications.",
    image: "/images/phone.svg",
  },
  "4": {
    title: "First & Last Name",
    desc: "Your name helps us personalize your experience and communication. It will be used in your account profile and displayed in relevant sections of DecorFlow.",
    image: "/images/phone.svg",
  },
  "5": {
    title: "Password",
    desc: "Your password ensures your account is secure. Please create a strong password to protect your personal and company information.",
    image: "/images/password.svg",
  },
  "6": {
    title: "Account Created",
    desc: "Congratulations! Your account has been set up. Click the 'Log In' button to access your dashboard and start managing your projects with DecorFlow.",
    image: "/images/verify-email.svg",
  },
} as const;

const Signup = () => {
  const { isLoading: loading } = useAuth();
  const dispatch = useAppDispatch();
  const { createTenant, checkUniqueFields } = useSignup();
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm<SignupForm>({
    defaultValues: {
      companyName: "",
      phoneNumber: "",
      email: "",
      password: "",
      cnfPassword: "",
      website: "",
      firstName: "",
      lastName: "",
    },
  });

  const [step, setStep] = useState(1);
  const [signupData, setSignupData] = useState<SignupForm | undefined>();

  const isLoading = useAppSelector((state) => state.ui.showBackdrop);

  const onSubmit: SubmitHandler<SignupForm> = async (data) => {
    const finalData = {
      ...signupData,
      ...data,
      phoneNumber: `+91${data.phoneNumber}`,
    } as SignupForm;
    setSignupData(finalData);

    if (step === 5) {
      if (finalData.password !== finalData.cnfPassword) {
        ["password", "cnfPassword"].forEach((field) =>
          setError(field as keyof SignupForm, {
            message: "Password & Confirm password do not match",
          })
        );
        return;
      }

      const result = await createTenant(finalData);

      if (result) setStep(6);
      return;
    }

    if (step === 2) {
      if (!(await checkUniqueFields(finalData["email"]))) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Email already exists",
            type: "error",
          })
        );
        return;
      }
    }

    setStep((prev) => prev + 1);
  };

  return !step || loading ? null : (
    <div className="max-w-screen-xl h-screen grid grid-cols-1 md:grid-cols-2 mx-auto">
      <div className="hidden p-10 border-r md:block">
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <img
            className="h-96 w-auto"
            src={LEFT_CONTENT[`${step}` as keyof typeof LEFT_CONTENT].image}
            alt={LEFT_CONTENT[`${step}` as keyof typeof LEFT_CONTENT].title}
          />
          <h2 className="text-2xl font-semibold text-primary">
            {LEFT_CONTENT[`${step}` as keyof typeof LEFT_CONTENT].title}
          </h2>
          <div className="text-lg">
            {LEFT_CONTENT[`${step}` as keyof typeof LEFT_CONTENT].desc}
          </div>
        </Box>
      </div>
      <div className="bg-signup bg-no-repeat bg-contain h-full bg-bottom p-10 flex flex-col items-center">
        <div className="text-3xl font-semibold mb-10 sm:text-4xl">
          Let’s get started
        </div>
        <Paper
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "100%",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {step === 1 && (
            <>
              <InputField
                id="companyName"
                label="Company Name"
                placeholder="Enter your company name"
                size="medium"
                fullWidth
                {...register("companyName", { required: true })}
                required
                variant="outlined"
                error={!!errors.companyName}
              />
              <InputField
                id="website"
                label="Company website (Optional)"
                placeholder="Company website (Optional)"
                size="medium"
                fullWidth
                {...register("website")}
                variant="outlined"
              />
            </>
          )}
          {step === 2 && (
            <InputField
              id="email"
              label="Email"
              placeholder="Enter your email"
              size="medium"
              fullWidth
              {...register("email", { required: true })}
              required
              variant="outlined"
              error={!!errors.email}
            />
          )}
          {step === 3 && (
            <InputField
              id="phoneNumber"
              label="Phone Number"
              placeholder="Enter your Phone Number"
              fullWidth
              {...register("phoneNumber", { required: true, maxLength: 10 })}
              required
              size="medium"
              variant="outlined"
              error={!!errors.phoneNumber}
              inputProps={{ maxLength: 10 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          )}
          {step === 4 && (
            <>
              <InputField
                id="firstName"
                label="First Name"
                placeholder="Enter your First Name"
                fullWidth
                {...register("firstName", { required: true })}
                required
                size="medium"
                variant="outlined"
                error={!!errors.firstName}
              />
              <InputField
                id="lastName"
                label="Last Name"
                placeholder="Enter your Last Name"
                fullWidth
                {...register("lastName", { required: true })}
                required
                size="medium"
                variant="outlined"
                error={!!errors.lastName}
              />
            </>
          )}
          {step === 5 && (
            <>
              <InputField
                id="password"
                label="Password"
                placeholder="Enter Password"
                type="password"
                fullWidth
                {...register("password", { required: true })}
                required
                size="medium"
                variant="outlined"
                error={!!errors.password}
              />
              <InputField
                id="cnfPassword"
                label="Confirm Password"
                placeholder="Re Enter Password"
                type="password"
                fullWidth
                {...register("cnfPassword", { required: true })}
                required
                size="medium"
                variant="outlined"
                error={!!errors.cnfPassword}
              />
            </>
          )}
          {step === 6 && (
            <>
              <div>
                Your account has been successfully created! Please log in to
                your account to get started.
              </div>
              <Link to="/login">
                <Button variant="contained" size="large" disabled={isLoading}>
                  Log In
                </Button>
              </Link>
            </>
          )}
          {step !== 6 && (
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading}
            >
              Continue
            </Button>
          )}
        </Paper>
        {step === 1 && (
          <Box sx={{ mt: 3, display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="body1" fontWeight="600">
              Already have an account?
            </Typography>
            <Link to="/login">
              <Button fullWidth variant="outlined">
                Login
              </Button>
            </Link>
          </Box>
        )}
      </div>
    </div>
  );
};

export default Signup;
