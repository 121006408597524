import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setShowSnackbar } from "../../store/ui.store";

const AlertMessage = () => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector((state) => state.ui.snackbar);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setShowSnackbar({ open: false }));
  };

  return (
    <Snackbar
      className="print:hidden"
      open={snackbar.open}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.type}
        // sx={{ width: "80%" }}
      >
        {snackbar.msg}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
