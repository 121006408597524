import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import TenantsDialog from "../../components/TenantsDialog";
import { useAuth } from "../../utils/AuthContext";

interface LoginForm {
  email: string;
  password: string;
}
const LoginPage = () => {
  const { control, handleSubmit, getValues } = useForm<LoginForm>({
    defaultValues: { email: "", password: "" },
  });
  const { signIn, isLoading } = useAuth();

  const [tenantIds, settenantIds] = useState<string[]>([]);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    const ids = await signIn(data.email, data.password, undefined);
    if (ids) {
      if (ids.length === 1) {
        signIn(data.email, data.password, ids[0]);
      } else {
        settenantIds(ids);
      }
    }
  };

  return isLoading ? null : (
    <Grid container component="main" sx={{ height: "100vh" }}>
      {tenantIds.length > 0 && (
        <TenantsDialog
          open={tenantIds.length !== 0}
          onClose={() => settenantIds([])}
          onSelected={(tenantId) => {
            signIn(getValues("email"), getValues("password"), tenantId);
          }}
          tenantIds={tenantIds}
        />
      )}
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(/images/login.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/logo_text.svg" alt="logo" width={300} />

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  margin="dense"
                  fullWidth
                  required
                  {...field}
                  error={!!error}
                  helperText={!!error && "Invalid Email"}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  margin="dense"
                  fullWidth
                  required
                  autoComplete="current-password"
                  {...field}
                  error={!!error}
                  helperText={!!error && "Invalid Password"}
                />
              )}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to="/forgot-password">
                <Button variant="text" size="small">
                  Forgot password?
                </Button>
              </Link>
            </Box>
            <Typography variant="body1" fontWeight="600" sx={{ mt: 2 }}>
              Don't have an account?
            </Typography>
            <Link to="/signup">
              <Button fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }}>
                Sign Up
              </Button>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
