import {
  User,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendEmailVerification,
  ActionCodeSettings,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { auth, functions } from "../firebase";
import useError from "../hooks/useError";
import { setUser } from "../store/data.store";
import { useAppDispatch } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";

interface AuthContextProps {
  currentUser: User | null;
  signIn: (
    email: string,
    password: string,
    tenantId?: string
  ) => Promise<string[] | null>;
  sendPasswordReset: (email: string) => Promise<boolean>;
  signOut: () => Promise<void>;
  sendVerificationEmail: () => Promise<boolean>;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  signIn: async () => null,
  signOut: async () => {},
  sendVerificationEmail: async () => true,
  sendPasswordReset: async () => false,
  isLoading: false,
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleError = useError();

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);

  const updateState = useCallback(
    async (user: User | null) => {
      // Set backdrop false regardless.
      dispatch(setShowBackdrop(false));
      // Navigate based on user state and verification.
      if (
        !user &&
        !["/signup", "/forgot-password"].includes(window.location.pathname)
      ) {
        navigate("/login");
      } else if (user && user.tenantId) {
        // If user exists but email not verified, navigate to verify account page.
        if (!user.emailVerified) {
          navigate("/verify-account");
        }
        // If tenantId exists and user is on login or signup page, navigate to home.
        else if (
          ["/login", "/signup", "/verify-account", "/forgot-password"].includes(
            window.location.pathname
          )
        ) {
          navigate("/");
        }
      }
      //  else if (user && !user.tenantId) {
      //   await auth.signOut();
      //   navigate("/login");
      // }
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        dispatch(setUser(user));
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      // This will fire on initial load and whenever the ID token changes.
      // You can then perform navigation based on updated user info.
      updateState(user);
    });
    return unsubscribe;
  }, [updateState]);

  const signIn = async (email: string, password: string, tenantId?: string) => {
    dispatch(setShowBackdrop(true));

    try {
      if (tenantId && auth.currentUser?.uid) {
        auth.tenantId = tenantId;

        const createCustomToken = httpsCallable<
          { uid: string | undefined; tenantId: string },
          { token: string }
        >(functions, "createCustomToken");

        const { data } = await createCustomToken({
          uid: auth.currentUser?.uid,
          tenantId,
        });

        // Tenant-specific sign-in
        await signInWithCustomToken(auth, data.token);
        await auth.currentUser?.reload(); // Ensure updated emailVerified status
      } else {
        // Standard email/password sign-in
        await signInWithEmailAndPassword(auth, email, password);

        const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
        return idTokenResult?.claims.tenants
          ? Object.keys(idTokenResult.claims.tenants)
          : null;
      }

      return null;
    } catch (error) {
      handleError(error);
      return null;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const sendPasswordReset = async (email: string) => {
    dispatch(setShowBackdrop(true));
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const sendVerificationEmail = async (): Promise<boolean> => {
    if (!currentUser) return true;
    await currentUser?.reload();

    if (currentUser?.emailVerified) return true;

    dispatch(setShowBackdrop(true));
    try {
      const actionCodeSettings: ActionCodeSettings = {
        url: `https://app.decorflow.in/`,
        handleCodeInApp: true,
      };

      await sendEmailVerification(currentUser, actionCodeSettings);
      // const sendEmail = httpsCallable(functions, "sendVerificationEmail");
      // await sendEmail({
      //   name: currentUser.displayName || "User",
      //   email: currentUser.email,
      //   tenantId: currentUser.tenantId,
      // });
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Verification link resent!",
          type: "info",
        })
      );
      return true;
    } catch (error) {
      handleError(error, "sendVerificationEmail");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const signOut = async (): Promise<void> => {
    dispatch(setShowBackdrop(true));
    try {
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn,
        signOut,
        isLoading,
        sendVerificationEmail,
        sendPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
