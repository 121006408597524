import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

interface Form {
  email: string;
}

const ForgotPasswordPage = () => {
  const { control, handleSubmit } = useForm<Form>({
    defaultValues: { email: "" },
  });

  const { sendPasswordReset, isLoading } = useAuth();
  const [message, setMessage] = useState<string | null>(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const onSubmit: SubmitHandler<Form> = async (data) => {
    if (isResendDisabled) return;
    const res = await sendPasswordReset(data.email);
    if (res) {
      setMessage(
        "A password reset email has been sent. Please check your inbox."
      );
      setIsResendDisabled(true);
      setTimer(60);
    }
  };

  // Countdown timer for resend button
  useEffect(() => {
    if (isResendDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isResendDisabled]);

  return isLoading ? (
    <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
  ) : (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(/images/forgot-password.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/logo_text.svg" alt="logo" width={300} />
          <Typography variant="h5" fontWeight="500">
            Reset Password
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
            Enter your email address below, and we will send you a link to reset
            your password.
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 2, width: "500px" }}
          >
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  margin="dense"
                  fullWidth
                  required
                  {...field}
                  error={!!error}
                  helperText={error?.message || ""}
                />
              )}
            />

            {message && (
              <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                {message}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isResendDisabled}
            >
              {isResendDisabled
                ? `Resend Email in ${timer}s`
                : "Send Reset Email"}
            </Button>

            <Box sx={{ mt: 3, display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="body1" fontWeight="600">
                Go back to Login?
              </Typography>
              <Link to="/login">
                <Button fullWidth variant="outlined">
                  Login
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
