import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { PROJECT_TYPE, PROJECT_STATUS, LEAD_STATUS } from "../utils/constants";
import { Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";
import { DISCOUNT_TYPE } from "../types";

export interface Lead {
  docId?: string;
  name: string;
  mobile: string;
  email?: string;
  company?: string;
  source: string;
  status: (typeof LEAD_STATUS)[number];
  type: "Lead" | "Client";
  address: Address;
}

export interface IStats {
  clients: number;
  leads: number;
  projects: number;
  revenue: number;
  profit: number;
}

export interface ILeadStats {
  [key: string]: number;
}

export interface Address {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  pincode: string;
}

export interface Project {
  docId: string;
  name: string;
  type: (typeof PROJECT_TYPE)[number];
  area?: {
    units?: string;
    value?: number;
  };
  bhk?: string;
  additionalType?: string;
  status: (typeof PROJECT_STATUS)[number];
  clientBudget: string;
  startDate: string;
  endDate: string;
  notes: string;
  address: Address;
  leadId: string;
  leadName: string;
  isClient: boolean;
  attachments: Attachment[];
  createdAt: Timestamp;
  floorPlan?: Attachment[];
  designs?: Attachment[];
}
export type ProjectCompact = Pick<Project, "docId" | "name" | "leadId">;

export interface Attachment {
  path: string;
  type: string;
  url: string;
}

export interface Profile {
  uid: string;
  email: string;
  role?: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  avatar?: Attachment;
  isAccepted?: boolean;
}

export interface Company {
  companyName: string;
  planDetails?: {
    planId: string;
    purchasedDate: Timestamp;
    expiryDate?: Timestamp;
    promocode?: string;
    orderId?: string;
  };
  displayName: string;
  logo: Attachment;
}

export interface IQuotation {
  docId?: string;
  status?: string;
  titles: Title[];
  leadId: string;
  projectId: string;
  projectName: string;
  createdAt?: Timestamp;
  createdBy: string;
  updatedBy: string;
  updatedAt: Timestamp;
  additionalSections: string[];
  discount?: {
    type: DISCOUNT_TYPE;
    value: number;
  } | null;
}

export interface Title {
  title: string;
  fields: Field[];
}

export interface Field {
  [key: string]: any;
}

export interface QuotationSettings {
  [key: string]: string;
}

export interface INotes {
  docId?: string | null;
  text: string;
  leadName: string;
  leadId: string;
  projectId?: string | null;
  projectName?: string | null;
  followupDate?: Timestamp | null;
  completed?: boolean;
  createdAt?: Timestamp | null;
  updatedAt?: Timestamp | null;
  createdBy: string;
  updatedBy?: string | null;
}

export interface IExpense {
  docId: string;
  title: string;
  date: string;
  amount: number;
  projectId: string;
  file?: Attachment;
  notes?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  createdBy: string;
  updatedBy: string;
}

export const STAT_TYPES = ["leads", "projects"] as const;

export interface IPlan {
  docId: string;
  type: string;
  desc: string;
  price: number;
  discount: number | null;
  features: string[];
  billingCycle: string;
}

export interface DataState {
  leads: Lead[];
  projects: Project[];
  projectsCompact: ProjectCompact[];
  notes: Record<string, INotes[]>;
  followups: INotes[];
  user?: User;
  profile?: Profile;
  company?: Company;
  users: Profile[];
  quotations: IQuotation[];
  quotation?: IQuotation;
  quotationFor?: {
    lead: Lead;
    project: Project;
  };
  quotationSettings?: QuotationSettings;
  stats?: Record<string, ILeadStats>;
  expenses: IExpense[];
  plans: IPlan[];
}

const initialState: DataState = {
  leads: [],
  projects: [],
  projectsCompact: [],
  users: [],
  quotations: [],
  notes: {},
  expenses: [],
  followups: [],
  plans: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLeads(state, action: PayloadAction<DataState["leads"]>) {
      state.leads = action.payload;
    },
    setProjects(state, action: PayloadAction<DataState["projects"]>) {
      state.projects = action.payload;
    },
    setProjectsCompact(
      state,
      action: PayloadAction<DataState["projectsCompact"]>
    ) {
      state.projectsCompact = action.payload;
    },
    setUser(state, action: PayloadAction<DataState["user"]>) {
      state.user = action.payload;
    },
    setProfile(state, action: PayloadAction<DataState["profile"]>) {
      state.profile = action.payload;
    },
    setCompany(state, action: PayloadAction<DataState["company"]>) {
      state.company = action.payload;
    },
    setUsers(state, action: PayloadAction<DataState["users"]>) {
      state.users = action.payload;
    },
    setQuotations(state, action: PayloadAction<DataState["quotations"]>) {
      state.quotations = action.payload;
    },
    setQuotation(state, action: PayloadAction<DataState["quotation"]>) {
      state.quotation = action.payload;
    },
    setQuotationFor(state, action: PayloadAction<DataState["quotationFor"]>) {
      state.quotationFor = action.payload;
    },
    setQuotationSettings(
      state,
      action: PayloadAction<DataState["quotationSettings"]>
    ) {
      state.quotationSettings = action.payload;
    },
    setNotes(state, action: PayloadAction<DataState["notes"]>) {
      // Extract the first record
      const [id, notes] = Object.entries(action.payload)[0];
      state.notes[id] = notes;
    },
    setStats(state, action: PayloadAction<DataState["stats"]>) {
      state.stats = state.stats || {};
      for (const key in action.payload) {
        state.stats[key] = {
          ...(state.stats[key] || {}),
          ...action.payload[key],
        };
      }
    },
    setExpenses(state, action: PayloadAction<DataState["expenses"]>) {
      state.expenses = action.payload;
    },
    setFollowups(state, action: PayloadAction<DataState["followups"]>) {
      state.followups = action.payload;
    },
    setPlans(state, action: PayloadAction<DataState["plans"]>) {
      state.plans = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLeads,
  setProjects,
  setUser,
  setProfile,
  setCompany,
  setUsers,
  setQuotations,
  setQuotation,
  setQuotationFor,
  setQuotationSettings,
  setNotes,
  setStats,
  setExpenses,
  setProjectsCompact,
  setFollowups,
  setPlans,
} = dataSlice.actions;

export default dataSlice.reducer;
