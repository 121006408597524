import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../firebase";
import useError from "./useError";

const useFileUpload = () => {
  const handleError = useError();

  const uploadFile = async (file: File | Blob, path: string) => {
    const storageRef = ref(storage, path);

    return uploadBytes(storageRef, file).then(async (snapshot) => {
      return {
        path: snapshot.ref.fullPath,
        url: await getDownloadURL(snapshot.ref),
        type: `${snapshot.metadata.contentType}`,
      };
    });
  };

  const deleteFile = async (path: string) => {
    try {
      const storageRef = ref(storage, path);
      await deleteObject(storageRef);
    } catch (error) {
      handleError(error);
    }
  };

  return { uploadFile, deleteFile };
};

export default useFileUpload;
