import { Backdrop, CircularProgress } from "@mui/material";
import { useAppSelector } from "../../store/hooks";

const Loader = ({ show = false }: { show?: boolean }) => {
  const showBackdrop = useAppSelector((state) => state.ui.showBackdrop);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={show || showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
